<template>
  <div>
    <router-link class="come-back" to="/admin/onk_document">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">Создание документа</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="AddDocuments" ref="AddDocuments">
        <div class="content-sb">
          <p>Название документа<span>*</span></p>
          <input required placeholder="Введите название документа" type="text" name="title" class="input blue">
        </div>

        <div class="content-sb">
          <p>Приоритет<span>*</span></p>
          <input required placeholder="Введите название документа" min="0" type="text" name="order" class="input blue">
        </div>

        <div class="content-sb">
          <p>Документ<span>*</span></p>
          <input required  type="file" name="file"  class="input blue">
        </div>

      <div class="content-sb">
        <p>Впишите теги через запятую</p>
        <tags @dataTags="dataTags" />
      </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/onk_document" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
import Tags from '@/components/Admin/Tags'

export default {
  name: 'createDocuments',
  data () {
    return {
      tagList: null,
      systemTagList: ['/onk_document']
    }
  },
  methods: {
    AddDocuments () {
      const formData = new FormData(this.$refs.AddDocuments)
      formData.append('systemTagList', JSON.stringify(this.systemTagList))
      if (this.tagList) formData.append('tagList', JSON.stringify(this.tagList))
      this.$store.dispatch('AddDocuments', formData)
    },
    dataTags (value) {
      this.tagList = value
    }
  },
  components: {
    TricolorLine,
    Tags
  }
}
</script>

<style lang="scss">
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
}

.select, .input {
  width: 33.75rem;
}
</style>
